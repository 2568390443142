import canvasContext from "canvas-context";

function lerp(a, b, n) {
  return (1 - n) * a + n * b;
}

export default class CustomCursor {
  constructor(links, options, damping = 0.17) {
    const { context } = canvasContext("2d", options);
    this.options = options;
    this.damping = damping;

    this.dPR = window.devicePixelRatio || 1;

    this.dx = 0;
    this.dy = 0;

    this.clientX = 0;
    this.clientY = 0;

    this.context = context;
    this.context.canvas.style.position = "fixed";
    this.context.canvas.style.top = 0;
    this.context.canvas.style.left = 0;
    this.context.canvas.style.width = `${options.width / this.dPR}px`;
    this.context.canvas.style.height = `${options.height / this.dPR}px`;
    this.context.canvas.style.pointerEvents = "none";
    this.context.canvas.style.zIndex = 9999;

    for (const link of links) {
      this.addLink(link);
    }

    document.body.style.cursor = "none";

    document.body.appendChild(this.context.canvas);

    document.addEventListener("mousemove", this.onMouseMove.bind(this));
  }

  addLink(link) {
    link.addEventListener("mouseenter", this.onMouseEnter.bind(this));
    link.addEventListener("mouseleave", this.onMouseLeave.bind(this));
  }

  removeLink(link) {
    link.removeEventListener("mouseenter", this.onMouseEnter.bind(this));
    link.removeEventListener("mouseleave", this.onMouseLeave.bind(this));
  }

  onMouseMove(event) {
    this.clientX = event.clientX;
    this.clientY = event.clientY;
  }

  onMouseEnter(event) {
    this.active = event.currentTarget;
  }
  onMouseLeave() {
    this.active = null;
  }

  render() {
    this.context.clearRect(0, 0, this.options.width, this.options.height);
    this.context.save();

    this.dx = lerp(this.dx, this.clientX, this.damping);
    this.dy = lerp(this.dy, this.clientY, this.damping);

    this.dx = Math.floor(this.dx * 10000) / 10000;
    this.dy = Math.floor(this.dy * 10000) / 10000;

    this.context.strokeStyle = getComputedStyle(document.body).getPropertyValue(
      "--color-dark-grey"
    );
    this.context.fillStyle = getComputedStyle(document.body).getPropertyValue(
      "--color-accent"
    );
    this.context.beginPath();
    this.context.translate(
      this.options.width * 0.5 + 0.5,
      this.options.height * 0.5 + 0.5
    );

    this.context.arc(0, 0, this.active ? 10 : 4, 0, Math.PI * 2);
    this.context.fill();

    this.context.beginPath();
    if (!this.active) {
      this.context.translate(this.dx - this.clientX, this.dy - this.clientY);
    }
    this.context.arc(0, 0, 14, 0, Math.PI * 2);
    this.context.stroke();

    this.context.restore();

    this.context.canvas.style.left = `${
      this.clientX - this.options.width * (0.5 / this.dPR)
    }px`;
    this.context.canvas.style.top = `${
      this.clientY - this.options.height * (0.5 / this.dPR)
    }px`;
  }
}
