// import Engine from "raf-perf";
// import canvasContext from "canvas-context";

import Background from "./Background/Background.js";
import Header from "./Header/Header.js";
import Footer from "./Footer/Footer.js";
import Works from "./Works/Works.js";
import Blog from "./Blog/Blog.js";
import About from "./About/About.js";
import Links from "./Links/Links.js";

import w from "../utils/window.js";
import matchPath from "../utils/matchPath.js";
import Tatooine from "../utils/tatooine/Tatooine.js";
import CustomCursor from "../utils/CustomCursor.js";

export default class AppView {
  constructor(app, props) {
    this.app = app;
    this.props = props;

    this.views = [];

    // this.offset = 0;
    // this.backgroundAlpha = 0;
    // this.fadeOut = true;

    this.pages = new Map()
      .set("", "Works")
      .set("/experienced", "Sketches")
      .set("/blog/:id", "Article")
      .set("/blog", "Blog")
      .set("/works/:id", "Works")
      .set("/works", "Works")
      .set("/about", "About")
      .set("/links", "Links");
  }

  init() {
    // Context
    // this.gl = canvasContext("webgl", {
    //   // preserveDrawingBuffer: true,
    //   // alpha: false,
    //   // premultipliedAlpha: false
    // }).context;

    // Engine
    // this.engine = new Engine();
    // this.engine.on("tick", this.tick.bind(this));

    // Entities
    const worksElement = this.app.element.querySelector(".Works");
    if (worksElement) {
      this.works = new Works(worksElement, this.props);
      this.works.init();
      this.views.push(this.works);
    }

    const experiencedElement = this.app.element.querySelector(".Experienced");
    if (experiencedElement) {
      this.experienced = new Works(experiencedElement, {
        ...this.props,
        path: "/experienced",
      });
      this.experienced.init();
      this.views.push(this.experienced);
    }

    const blogElement = this.app.element.querySelector(".Blog");
    if (blogElement) {
      this.blog = new Blog(blogElement, this.props);
      this.blog.init();
      this.views.push(this.blog);
    }

    const backgroundElement = document.querySelector(".Background");
    if (backgroundElement) {
      this.background = new Background(backgroundElement, {
        ...this.props,
        // gl: this.gl,
        images: this.works && Array.from(this.works.images),
      });
      this.views.push(this.background);
      this.background.init();
    }

    const aboutElement = this.app.element.querySelector(".About");
    if (aboutElement) {
      this.about = new About(
        this.app.element.querySelector(".About"),
        this.props
      );
      this.about.init();
      this.views.push(this.about);
    }

    const linksElement = this.app.element.querySelector(".Links");

    if (linksElement) {
      this.links = new Links(
        this.app.element.querySelector(".Links"),
        this.props
      );
      this.links.init();
      this.views.push(this.links);
    }

    this.header = new Header(
      this.app.element.querySelector(".Header"),
      this.props
    );
    this.header.init();
    this.views.push(this.header);

    this.footer = new Footer(
      this.app.element.querySelector(".Footer"),
      this.props
    );
    // this.footer.init();
    this.views.push(this.footer);

    // Listeners
    window.addEventListener("resize", this.onResize.bind(this));
    this.onResize();
    window.addEventListener("scroll", this.onScroll.bind(this), {
      passive: true,
    });
    this.onScroll();

    // if (this.background && this.works) {
    //   Array.from(this.works.titles).forEach((link) => {
    //     link.addEventListener("mouseenter", this.onMouseEnter);
    //     link.addEventListener("touchstart", this.onMouseEnter, {
    //       passive: true,
    //     });
    //   });
    //   Array.from(this.works.titles).forEach((link) => {
    //     link.addEventListener("mouseleave", this.onMouseLeave);
    //     link.addEventListener("touchend", this.onMouseLeave, { passive: true });
    //   });
    // }

    if (!this.props.isMobile) {
      this.cursor = new CustomCursor(
        [
          ...(this.works?.links || []),
          ...(this.works?.moreLinks || []),
          ...(this.blog?.links || []),
          ...(this.about?.links || []),
          ...(this.links?.links || []),
          ...(this.header?.links || []),
          ...(this.footer?.links || []),
        ],
        {
          width: 256,
          height: 256,
        }
      );
    }

    // this.engine.start();
    this.tick = this.tick.bind(this);
    requestAnimationFrame(this.tick);
  }

  tick() {
    // if (!this.gl) return;

    Tatooine.update(Date.now());

    // if (this.background) {
    //   this.background.render(this.currentWork, this.backgroundAlpha);
    // }
    if (this.cursor) this.cursor.render();
    // this.background.render(this.works.currentItem, 1);

    // if (this.fadeOut) {
    //   this.backgroundAlpha -= 0.08;
    //   if (this.backgroundAlpha <= 0) {
    //     this.currentWork = null;
    //   }
    // } else {
    //   // TODO: easing
    //   this.backgroundAlpha += 0.08;
    // }
    // this.backgroundAlpha = Math.max(Math.min(this.backgroundAlpha, 1.0), 0.0);

    this.views.forEach((view) => view.tick());

    requestAnimationFrame(this.tick);
  }

  // onMouseEnter = (event) => {
  //   event.stopPropagation();
  //   // Add classes
  //   this.works.element.classList.add("active");
  //   this.app.element.classList.add("Main--workActive");

  //   for (let link of this.works.titles) {
  //     link.classList.remove("active");
  //   }
  //   event.currentTarget.classList.add("active");

  //   // Update transition
  //   this.fadeOut = false;
  //   const currentWork = [].indexOf.call(
  //     Array.from(this.works.titles),
  //     event.currentTarget
  //   );
  //   if (currentWork !== this.currentWork) {
  //     this.backgroundAlpha = 0;
  //   }
  //   this.currentWork = currentWork;
  // };

  // onMouseLeave = (event) => {
  //   event.stopPropagation();
  //   this.works.element.classList.remove("active");
  //   this.app.element.classList.remove("Main--workActive");
  //   this.currentWork = null;
  //   this.fadeOut = true;
  //   // if (this.backgroundTimeout) clearTimeout(this.backgroundTimeout);
  //   // this.backgroundTimeout = setTimeout(() => {
  //   //   this.fadeOut = true;
  //   // }, 2000);
  // };

  onScroll(event) {
    this.works?.scroll();
    this.experienced?.scroll();
    this.about?.scroll();
    this.links?.scroll();

    if (this.cursor) this.cursor.render(event);

    // this.offset = Math.max(
    //   Math.min(window.scrollY / this.bodyHeight, 1.0),
    //   0.0
    // );
    // const currentWork = Math.floor(
    //   (this.works.images.length - 1) * this.offset
    // );
    // if (currentWork !== this.currentWork) {
    //   this.fadeOut = false;
    //   this.backgroundAlpha = 0;
    // }
    // this.currentWork = currentWork;

    // if (this.backgroundTimeout) clearTimeout(this.backgroundTimeout);
    // this.backgroundTimeout = setTimeout(() => {
    //   this.fadeOut = true;
    // }, 2000);

    this.views.forEach((view) => view.scroll());
  }

  updateCanonicalLink() {
    const link = !!document.querySelector("link[rel='canonical']")
      ? document.querySelector("link[rel='canonical']")
      : document.createElement("link");
    link.setAttribute("rel", "canonical");
    link.setAttribute(
      "href",
      location.protocol + "//" + location.host + location.pathname
    );
    document.head.appendChild(link);
  }

  update({ location, previousLocation }) {
    let viewsToShow = [];

    if (
      matchPath(location.pathname, {
        path: "/blog/:id",
      }) ||
      matchPath(location.pathname, {
        path: "/works/:id",
      })
    ) {
      this.header.useHistory = false;
    }

    this.views.forEach((view) => {
      let match = false;

      if (Array.isArray(view.path)) {
        match = view.path.some((path) =>
          matchPath(location.pathname, {
            ...view.pathOptions,
            path,
          })
        );
      } else {
        match = matchPath(location.pathname, {
          ...view.pathOptions,
          path: view.path,
        });
      }
      if (match) {
        viewsToShow.push(view);
      }
    });

    viewsToShow = [...new Set(viewsToShow)];
    viewsToShow.forEach((view) => view.show({ location, previousLocation }));

    this.views
      .filter((view) => !viewsToShow.includes(view))
      .forEach((view) => view.hide({ location, previousLocation }));

    if (this.background) {
      const pageTitle = Array.from(this.pages.entries()).find((page) =>
        matchPath(location.pathname, {
          exact: true,
          path: page[0],
        })
      )[1];
      this.background.update(pageTitle);
    }
    if (this.header) {
      this.header.update(location.pathname);
    }
    this.updateCanonicalLink();
  }

  onResize() {
    this.bodyHeight = document.body.offsetHeight - w.height;

    // if (this.gl) {
    //   this.gl.canvas.width = w.width;
    //   this.gl.canvas.height = w.height;
    //   this.gl.canvas.style.width = `${w.width}px`;
    //   this.gl.canvas.style.height = `${w.height}px`;
    // }

    this.views.forEach((view) => view.resize());
  }
}
