import { createBrowserHistory } from "history";

import AppView from "./components/AppView.js";

export default class App {
  constructor(element, options) {
    this.element = element;
    this.options = options;

    if ("ontouchstart" in window) {
      document.body.classList.add("is-touch-device");
    }
  }

  init() {
    this.initRouter();
    this.initView();
  }

  initView() {
    this.view = new AppView(this, {
      history: this.history,
      isMobile: "ontouchstart" in window,
    });

    this.view.init();
    this.view.update({ location: window.location });
  }

  initRouter() {
    this.history = createBrowserHistory();
    this.previousLocation = null;

    this.history.listen(({ location }) => {
      if (
        !this.previousLocation ||
        this.previousLocation.pathname !== location.pathname
      ) {
        this.view.update({
          location,
          previousLocation: { ...this.previousLocation },
        });
        this.previousLocation = location;
      }
    });
  }
}
