import "./Works.pcss";
import AbstractView from "../AbstractView.js";

// import normalizeWheel from "normalize-wheel";
// import { Lethargy } from "lethargy";
import SmoothScroll from "../../utils/SmoothScroll.js";
import {
  Tatooine,
  Tween,
  OutSine,
  InSine,
} from "../../utils/tatooine/index.js";

export default class WorksView extends AbstractView {
  currentItem = 0;
  animationTime = 200;

  constructor(element, props) {
    super(element, {
      path: "/",
      pathOptions: {
        exact: true,
        strict: false,
      },
      ...props,
    });
  }

  init() {
    this.workList = this.element.querySelector(".Works-list");
    this.items = this.element.querySelectorAll(".WorkItem");
    this.links = this.element.querySelectorAll(".WorkItem-link");
    this.moreLinks = this.element.querySelectorAll(".Work-more a");
    this.titles = this.element.querySelectorAll(".WorkItem-title");
    this.images = this.element.querySelectorAll(".WorkItem-picture");

    for (const link of this.links) {
      link.addEventListener("click", this.onLinkClick.bind(this));
    }

    for (const link of this.moreLinks) {
      link.addEventListener("click", this.onMoreLinkCLick.bind(this));
    }

    if (!this.isMobile) {
      this.smoothScroll = new SmoothScroll(
        this.element,
        this.element.querySelector(".Works-content")
      );
      this.smoothScroll.init();
    }

    const resizeObserver = new ResizeObserver((entries) => {
      this.resize();
    });

    resizeObserver.observe(this.workList);

    // Works-list

    // const intersectionObserver = new IntersectionObserver(
    //   entries => {
    //     entries.forEach(entry => {
    //       if (entry.intersectionRatio > 0) {
    //         entry.target.classList.add("active");
    //       } else {
    //         entry.target.classList.remove("active");
    //       }
    //     });
    //   },
    //   { threshold: 0.5 }
    // );

    // for (const item of this.items) {
    //   intersectionObserver.observe(item);
    // }

    // this.lethargy = new Lethargy();

    // this.animate();

    // document.addEventListener("mousewheel", this.onNavigate);
    // document.addEventListener("keyup", this.onNavigate);
  }

  // animate() {
  //   this.isAnimating = true;

  //   for (const item of this.items) {
  //     item.classList.remove("active");
  //   }

  //   Array.from(this.items)[this.currentItem].classList.add("active");

  //   setTimeout(() => {
  //     this.isAnimating = false;
  //   }, this.animationTime);
  // }

  // onNavigate = event => {
  //   const isKeyPress = event.keyCode;

  //   if (!isKeyPress && this.lethargy.check(event) === false) return;
  //   if (this.isAnimating) return;

  //   const next =
  //     (isKeyPress && event.keyCode === 40) || normalizeWheel(event).pixelY > 0;
  //   const prev = (isKeyPress && event.keyCode === 38) || !isKeyPress;

  //   if (next) {
  //     this.currentItem = Math.min(this.currentItem + 1, this.items.length - 1);
  //   } else if (prev) {
  //     this.currentItem = Math.max(this.currentItem - 1, 0);
  //   }
  //   this.animate();
  // };

  tick() {
    if (this.smoothScroll) this.smoothScroll.update();
  }

  scroll() {
    if (this.smoothScroll) this.smoothScroll.onScroll();
  }

  resize() {
    if (this.smoothScroll) this.smoothScroll.onResize();
  }

  onLinkClick(event) {
    gtag("event", "open", {
      event_category: "Work Link",
      event_label: event.currentTarget.href,
    });
  }

  onMoreLinkCLick(event) {
    const isLocal = event.currentTarget.target !== "_blank";
    if (isLocal) {
      event.preventDefault();
      this.history.push(event.currentTarget.pathname);
    }

    gtag("event", "open", {
      event_category: "Work More Link",
      event_label: event.currentTarget.href,
    });
  }

  show({ previousLocation }) {
    this.status = "showing";
    this.element.style.pointerEvents = "none";

    super.show();
    this.location = location;

    window.scrollTo(0, 0);
    this.currentItem = 0;

    setTimeout(() => {
      [...this.items, ...this.moreLinks].forEach((item, i) => {
        Tatooine.stopTweensOf(item);
        new Tween(item.style, "opacity", {
          to: 1,
          ease: OutSine,
          duration: 300,
          delay: (previousLocation ? 1000 : 1300) + i * 100,
          onStop: () => {
            if (
              i === this.items.length - 1 &&
              this.status !== "hidding" &&
              this.status !== "hidden"
            ) {
              this.element.style.pointerEvents = "auto";
              this.status = "showed";
            }
          },
          decode(value) {
            return Number(value || 0);
          },
        }).start();
      });
    }, 100);
  }

  hide() {
    this.status = "hidding";
    this.element.style.pointerEvents = "none";

    [...this.items, ...this.moreLinks].forEach((item, i) => {
      Tatooine.stopTweensOf(item);
      new Tween(item.style, "opacity", {
        to: 0,
        ease: InSine,
        duration: 300,
        onStop: () => {
          if (
            i === this.items.length - 1 &&
            this.status !== "showing" &&
            this.status !== "showed"
          ) {
            super.hide();
            this.status = "hidden";
          }
        },
        decode(value) {
          return Number(value || 0);
        },
      }).start();
    });
  }
}
