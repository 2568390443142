import Tatooine from "./Tatooine.js";
import { OutExpo } from "./Easing.js";

const defaultOptions = {
  from: 0,
  to: 1,
  duration: 2000, // milliseconds
  delay: 0, // milliseconds
  ease: OutExpo,
  yoyo: false,
  repeat: false,
  progress: 0,
  startTime: null,
  onUpdate() {},
  onStop() {},
  encode(value) {
    return value;
  },
  decode(value) {
    return value;
  },
};

export default class Tween {
  constructor(object, property, options) {
    this.object = object;
    this.property = property;

    Object.assign(this, defaultOptions, options);

    this.from = this.object[this.property]
      ? this.decode(this.object[this.property])
      : this.from;

    this.isActive = false;

    // Steps
    if (Array.isArray(this.to) && this.to.length) {
      this.steps = this.to;
      this.currentStep = 0;
      this.to = this.steps[0];
    }
  }

  start() {
    requestAnimationFrame(() => {
      this.startTime = Tatooine.last + this.delay;
      this.change = this.to - this.from;

      Tatooine.add(this);
      this.isActive = true;
    });

    return this;
  }

  stop(immediateStop) {
    this.isActive = false;

    if (immediateStop) {
      this.immediateStop = true;
    } else {
      this.onStop();
      this.startTime = null;
      Tatooine.remove(this);
    }

    return this;
  }

  update() {
    if (Tatooine.last < this.startTime || !this.startTime) {
      return;
    }

    const deltaTime = Tatooine.last - this.startTime;
    const elapsed = deltaTime / this.duration;

    // Update
    const value = this.encode(
      this.ease(deltaTime, this.decode(this.from), this.change, this.duration)
    );

    this.object[this.property] = value;
    this.onUpdate(value);

    // Complete
    if (elapsed > 1) {
      // HACK
      this.object[this.property] = this.to;
      // if (this.from < this.to) {
      //   this.object[this.property] = this.encode(
      //     Math.min(this.object[this.property], this.to)
      //   );
      // } else if (this.from > this.to) {
      //   this.object[this.property] = this.encode(
      //     Math.max(this.object[this.property], this.to)
      //   );
      // }

      // Steps
      if (this.steps) {
        this.currentStep++;
        this.to = this.steps[this.currentStep];
        // Next
        if (this.to !== undefined) {
          this.start();
          return this;
        }
        // Reset
        this.currentStep = 0;
        this.to = this.steps[this.currentStep];
      }

      if (this.immediateStop) {
        this.immediateStop = false;
        this.stop();
      } else if (this.repeat > 0) {
        this.repeat--;
        this.start();
      } else if (this.yoyo) {
        if (this.steps) {
          this.steps.reverse();
          this.currentStep = 1;
          this.to = this.steps[this.currentStep];
        } else {
          [this.from, this.to] = [this.to, this.from];
        }
        this.start();
      } else {
        this.stop();
      }
    }
    return this;
  }
}
