import "./Links.pcss";

import AbstractView from "../AbstractView.js";
import SmoothScroll from "../../utils/SmoothScroll.js";
import {
  Tatooine,
  Tween,
  OutSine,
  InSine,
} from "../../utils/tatooine/index.js";

export default class Links extends AbstractView {
  constructor(element, props) {
    super(element, { path: "/links", ...props });
  }

  init() {
    this.items = this.element.querySelectorAll(".WorkItem");
    this.links = this.element.querySelectorAll(".Link");

    if (!this.isMobile) {
      this.smoothScroll = new SmoothScroll(
        this.element,
        this.element.querySelector(".Links-content")
      );
      this.smoothScroll.init();
    }
  }

  tick() {
    if (this.smoothScroll) this.smoothScroll.update();
  }

  scroll() {
    if (this.smoothScroll) this.smoothScroll.onScroll();
  }

  resize() {
    if (this.smoothScroll) this.smoothScroll.onResize();
  }

  show({ previousLocation }) {
    this.status = "showing";
    this.element.style.pointerEvents = "none";

    super.show();
    window.scrollTo(0, 0);
    if (this.smoothScroll) this.smoothScroll.onResize();

    requestAnimationFrame(() => {
      this.items.forEach((item, i) => {
        Tatooine.stopTweensOf(item);
        new Tween(item.style, "opacity", {
          to: 1,
          ease: OutSine,
          duration: 300,
          delay: (previousLocation ? 1000 : 1300) + i * 100,
          onStop: () => {
            if (
              i === this.items.length - 1 &&
              this.status !== "hidding" &&
              this.status !== "hidden"
            ) {
              this.element.style.pointerEvents = "auto";
              this.status = "showed";
            }
          },
          decode(value) {
            return Number(value || 0);
          },
        }).start();
      });
    });
  }

  hide() {
    this.status = "hidding";
    this.element.style.pointerEvents = "none";

    this.items.forEach((item, i) => {
      Tatooine.stopTweensOf(item);
      new Tween(item.style, "opacity", {
        to: 0,
        ease: InSine,
        duration: 300,
        onStop: () => {
          if (
            i === this.items.length - 1 &&
            this.status !== "showing" &&
            this.status !== "showed"
          ) {
            super.hide();
            this.status = "hidden";
          }
        },
        decode(value) {
          return Number(value || 0);
        },
      }).start();
    });
  }
}
