const w = {
  width: null,
  height: null,
  center: null,
  devicePixelRatio: window.devicePixelRatio || 1,

  init() {
    window.addEventListener("resize", this.onResize.bind(this));
    this.onResize();
  },

  get physicalWidth() {
    return this.width * this.devicePixelRatio;
  },

  get physicalHeight() {
    return this.height * this.devicePixelRatio;
  },

  onResize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.center = { x: this.width / 2, y: this.height / 2 };
  },
};

w.init();

export default w;
