function lerp(a, b, n) {
  return (1 - n) * a + n * b;
}

class SmoothScroll {
  constructor(
    container = document.body,
    element = document.querySelector("main"),
    damping = 0.07
  ) {
    this.container = container;
    this.element = element;
    this.damping = damping;

    this.sx = 0;
    this.sy = 0;

    this.dx = this.sx;
    this.dy = this.sy;
  }

  init() {
    this.container.style.height = this.element.clientHeight + "px";

    this.element.style.position = "fixed";
    this.element.style.top = 0;
    this.element.style.left = 0;
  }

  onScroll() {
    this.sx = window.pageXOffset;
    this.sy = window.pageYOffset;
  }

  onResize() {
    this.container.style.height = this.element.clientHeight + "px";
  }

  update() {
    this.dx = lerp(this.dx, this.sx, this.damping);
    this.dy = lerp(this.dy, this.sy, this.damping);

    this.dx =
      Math.floor(this.dx * Number.MAX_SAFE_INTEGER) / Number.MAX_SAFE_INTEGER;
    this.dy =
      Math.floor(this.dy * Number.MAX_SAFE_INTEGER) / Number.MAX_SAFE_INTEGER;

    this.element.style.transform = `translate3d(-${this.dx}px, -${this.dy}px, 0)`;
  }
}

export default SmoothScroll;
