class Tatooine {
  constructor() {
    this.tweens = new Set();
    this.delayedCalls = new Set();
    this.last = 0;
  }

  activePropertyTween(twn) {
    const property = twn.property;
    const object = twn.object;

    for (const tween of this.tweens) {
      if (tween.object === object && tween.property === property) {
        return tween;
      }
    }
    return undefined;
  }

  add(tween) {
    const activePropertyTween = this.activePropertyTween(tween);
    if (
      activePropertyTween &&
      activePropertyTween.yoyo === false &&
      !activePropertyTween.steps &&
      (activePropertyTween.repeat === 0 || activePropertyTween.repeat === false)
    ) {
      activePropertyTween.stop();
    }
    this.tweens.add(tween);
  }

  has(tween) {
    this.tweens.has(tween);
  }

  remove(tween) {
    this.tweens.delete(tween);
  }

  stopAll(immediateStop) {
    for (const tween of this.tweens) {
      tween.stop(immediateStop);
    }
  }

  stopTweensOf(object, property) {
    for (const tween of this.tweens) {
      if (tween.object === object) {
        if (property && tween.property === property) {
          tween.stop();
        } else {
          tween.stop();
        }
      }
    }
  }

  delayedCall(delay, callback) {
    this.delayedCalls.add({
      time: this.last + delay,
      callback,
    });
  }

  update(time) {
    this.last = time;

    for (const delayedCall of this.delayedCalls) {
      if (this.last > delayedCall.time) {
        delayedCall.callback();
        this.delayedCalls.delete(delayedCall);
      }
    }

    if (!this.tweens.size) {
      return;
    }
    for (const tween of this.tweens) {
      tween.update(time);
    }
  }
}

export { Tatooine };

export default new Tatooine();
