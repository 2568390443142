export default class AbstractView {
  constructor(element, props) {
    this.element = element;
    this.path = "/";
    this.pathOptions = {};
    this.status = "hidden";

    Object.assign(this, props);
  }

  tick() {}
  scroll() {}
  resize() {}

  show() {
    this.element.style.display = "block";

    this.element.classList.add("show");
    this.element && this.element.scrollTop;
  }

  hide() {
    this.element.classList.remove("show");
    this.element.style.display = "none";
    this.status = "hidden";
  }
}
