import "./Header.pcss";

import AbstractView from "../AbstractView.js";

export default class HeaderView extends AbstractView {
  useHistory = true;

  init() {
    this.links = this.element.querySelectorAll("a:not(.noop)");
    this.navLinks = this.element.querySelectorAll(".Navigation a:not(.noop)");

    for (const link of this.navLinks) {
      link.addEventListener("click", this.onLinkCLick.bind(this));
    }
  }

  show({ location }) {
    super.show();

    this.location = location;
  }

  update(pathname) {
    this.navLinks.forEach((element) => {
      if (element.getAttribute("href") === pathname) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  }

  onLinkCLick(event) {
    if (!this.useHistory) return;

    event.preventDefault();

    if (this.location.pathname !== event.currentTarget.pathname) {
      this.history.push(event.currentTarget.pathname);

      gtag("event", "open", {
        event_category: "Navigation Link",
        event_label: event.currentTarget.pathname,
      });
    }
  }
}
