import "./index.pcss";

import App from "./App.js";
import "./responsive.pcss";

document.addEventListener("DOMContentLoaded", async () => {
  if ("serviceWorker" in navigator) {
    try {
      await navigator.serviceWorker.register("/service-worker.js");
    } catch (error) {
      console.log("SW registration failed: ", error);
    }
  }
  window.app = new App(document.querySelector("main"), {});
  app.init();
});
