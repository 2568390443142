import "./Background.pcss";
// import baseVert from "../../assets/shaders/base.vert";
// import baseFrag from "../../assets/shaders/base.frag";

// import createShader from "gl-shader";
// import createTexture from "gl-texture2d";
// import ndarray from "ndarray";
// // import FastAverageColor from "fast-average-color/dist/index.es6";

import AbstractView from "../AbstractView.js";
import {
  Tatooine,
  Tween,
  InSine,
  OutSine,
} from "../../utils/tatooine/index.js";

// function isImageLoaded(imageElement) {
//   return imageElement.complete && imageElement.naturalHeight !== 0;
// }

// let pixels = ndarray(
//   new Float32Array([0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1]),
//   [2, 2, 4]
// );

export default class WebGLView extends AbstractView {
  constructor(element, props) {
    super(element, props);

    // if (this.gl) {
    //   this.element.appendChild(this.gl.canvas);
    //   this.element.insertAdjacentElement("afterbegin", this.gl.canvas);

    //   this.gl.canvas.style.backgroundColor = "transparent";
    //   this.gl.clearColor(0, 0, 0, 0);
    //   this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);
    //   this.gl.disable(this.gl.DEPTH_TEST);
    //   this.gl.enable(this.gl.BLEND);
    //   this.gl.blendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA);
    // }

    this.title = this.element.querySelector(".Background-title");
  }

  async init() {
    // if (this.gl) {
    //   this.shader = createShader(this.gl, baseVert, baseFrag);
    //   this.buffer = this.gl.createBuffer();
    //   this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.buffer);
    //   this.gl.bufferData(
    //     this.gl.ARRAY_BUFFER,
    //     new Float32Array([-1.0, -1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0]),
    //     this.gl.STATIC_DRAW
    //   );
    //   // TODO: make white
    //   this.dummyTexture = createTexture(this.gl, [2, 2, 4]);
    //   // const fac = new FastAverageColor();
    //   const getTexture = (image) => {
    //     const texture = createTexture(this.gl, image);
    //     texture.minFilter = this.gl.LINEAR;
    //     texture.magFilter = this.gl.LINEAR;
    //     return texture;
    //   };
    //   if (this.images) {
    //     this.textures = await Promise.all(
    //       this.images.map(async (image) => {
    //         if (isImageLoaded(image)) {
    //           return Promise.resolve(getTexture(image));
    //         } else {
    //           return new Promise((resolve) => {
    //             image.addEventListener("load", function load() {
    //               resolve(getTexture(image));
    //               image.removeEventListener("load", load);
    //             });
    //           });
    //         }
    //       })
    //     );
    //   }
    // }
  }

  update(text) {
    Tatooine.stopTweensOf(this.title.style);

    setTimeout(() => {
      new Tween(this.title.style, "opacity", {
        to: 0,
        duration: 300,
        ease: InSine,
        onStop: () => {
          this.title.innerText = text;

          new Tween(this.title.style, "opacity", {
            to: 1,
            duration: 300,
            delay: 1000,
            ease: OutSine,
            decode(value) {
              return Number(value || 0);
            },
          }).start();
        },
        decode(value) {
          return Number(value || 0);
        },
      }).start();
    }, 100);
  }

  render(currentWork, alpha) {
    // if (!this.images) return;
    // if (this.gl) {
    //   // Bind shader
    //   this.shader.bind();
    //   // Set attributes
    //   this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.buffer);
    //   this.shader.attributes.position.pointer();
    //   // Set uniforms
    //   this.shader.uniforms.alpha = alpha || 0;
    //   this.shader.uniforms.time += 0.03;
    //   // this.shader.uniforms.offset = offset;
    //   this.shader.uniforms.resolution = [
    //     this.gl.drawingBufferWidth,
    //     this.gl.drawingBufferHeight,
    //   ];
    //   const currentTexture =
    //     (this.textures && this.textures[currentWork]) || this.dummyTexture;
    //   // this.shader.uniforms.textureColor = (currentTexture &&
    //   //   currentTexture.color) || [1, 1, 1, 1];
    //   this.shader.uniforms.textureResolution = [
    //     currentTexture.width,
    //     currentTexture.height,
    //   ];
    //   this.shader.uniforms.texture = currentTexture.bind();
    //   if (!(this.textures && this.textures[currentWork])) {
    //     this.dummyTexture.setPixels(pixels);
    //   }
    //   // Draw
    //   this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
    // }
  }

  resize() {
    // if (this.gl) {
    //   this.gl.viewport(
    //     0,
    //     0,
    //     this.gl.drawingBufferWidth,
    //     this.gl.drawingBufferHeight
    //   );
    // }
  }
}
